//---------- @mixins ----------//

@mixin box-shadow($val...) {
	-webkit-box-shadow: ($val);
	box-shadow: ($val);
}

@mixin transition($val...) {
	-webkit-transition: ($val);
	transition: ($val);
}

@mixin translateY($val...) {
	-webkit-transform: translateY($val);
	transform: translateY($val);
}

@mixin translateX($val...) {
	-webkit-transform: translateX($val);
	transform: translateX($val);
}

@mixin rotate($val){
	-webkit-transform: rotate($val);
	transform: rotate($val);
}

@mixin scale($val){
	-webkit-transform: scale($val);
	transform: scale($val);
}